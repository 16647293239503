import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 100px 0 100px;
  z-index: 0;
  @media only screen and (max-width: 1220px) {
    padding: 100px 0 100px;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0px 0 0px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 0 51px; */
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1440px) {
    padding: 0;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 -15px;
  }

  .service_post {
    width: calc(100% / 4 - 8px);
    padding: 30px 30px;
    /* box-shadow: 0 0 100px rgba(175, 182, 194, 0.2); */
    background-color: ${themeGet("colors.lightBlue")};
    margin-bottom: 14px;
    text-align: left;
    max-height: 500px;
    @media only screen and (max-width: 1360px) {
      width: calc(100% / 2 - 24px);
      margin: 0 12px 25px;
    }
    @media only screen and (max-width: 991px) {
      width: calc(100% / 2 - 24px);
      padding: 30px 30px;
    }
    @media only screen and (max-width: 780px) {
      width: 100%;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 480px) {
      margin: 0 15px 30px;
      width: 100%;
      min-height: 0px;
    }

    .thumbnail {
      img {
        height: 40px;
        width: auto;
        margin-bottom: 0px;
        @media only screen and (max-width: 480px) {
          margin-bottom: 24px;
        }
      }
    }

    div {
      min-height: 80px;
      @media only screen and (max-width: 480px) {
        min-height: 0px;
      }
    }
    .title {
      margin-top: 15px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      @media only screen and (max-width: 990px) {
        font-size: 22px;
        margin: 0 0 24px;
      }
      @media only screen and (max-width: 480px) {
        line-height: 28px;
        font-size: 20px;
        margin: 0 0 16px;
      }
    }

    .titleAlt {
      margin-top: 0px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      @media only screen and (max-width: 990px) {
        font-size: 24px;
        margin: 0 0 24px;
      }
      @media only screen and (max-width: 480px) {
        line-height: 30px;
        font-size: 20px;
        margin: 0 0 16px;
      }
    }

    ul {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 48px;
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        margin-bottom: 0px;
      }
    }

    li {
      margin: 4px 0;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 26px;
      text-indent: -26px;
    }
  }
`
export const Discover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 95px 0px 0px 85px;
  box-shadow: 0 0 100px rgba(175, 182, 194, 0.2);
  background-color: ${themeGet("colors.mainBlue")};
  font-weight: 700;
  margin-bottom: 10px;
  text-align: left;
  width: 282px;
  max-height: 532px;
  min-height: 408px;
  @media only screen and (max-width: 1360px) {
    width: 47% !important;
  }
  @media only screen and (max-width: 780px) {
    width: 100% !important;
    min-height: 0px;
  }
  .number-wrapper {
    display: flex;
  }
  .icon {
    font-size: 60px;
    margin: 10px 10px 0px 0px;
    color: ${themeGet("colors.white")};
    margin-bottom: 0px;
    @media only screen and (max-width: 1220px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 60px;
      margin-bottom: 0px;
    }
  }
  .number {
    font-size: 82px;
    color: ${themeGet("colors.white")};
    margin-bottom: 0px;
    @media only screen and (max-width: 1220px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 60px;
      margin-bottom: 0px;
    }
  }

  .description {
    font-size: 20px;
    color: ${themeGet("colors.white")};
    margin-bottom: 70px;
    max-width: 150px;
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }

  .button {
    width: 266px;
    text-transform: inherit;
    border-radius: 0px;
    padding: 30px 50px;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid ${themeGet("colors.white")};
    &.button {
      &:hover {
        background-color: ${themeGet("colors.mainBlueHover")};
        border: 1px solid ${themeGet("colors.white")};
        color: ${themeGet("colors.mainBlue")};
      }
    }
    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover")};
      }
    }
  }

  @media only screen and (max-width: 1360px) {
    width: calc(100% / 3 - 30px);
    margin: 0 15px 25px;
  }
  @media only screen and (max-width: 991px) {
    padding: 30px 30px;
  }
  @media only screen and (max-width: 767px) {
    width: calc(100% / 2 - 30px);
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 30px 25px;
  }
`

export default SectionWrapper
