import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BannerWrapper = styled.div`
  margin-top: 76px;
  overflow: hidden;
  background-image: ${props => props.image && `url(${props.image})`};
  background-color: ${themeGet("colors.black")};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 600px;
  @media only screen and (max-width: 1920px) {
    height: 670px;
  }
  @media only screen and (max-width: 1680px) {
    height: 650px;
  }
  @media only screen and (max-width: 1440px) {
    height: 630px;
  }
  @media only screen and (max-width: 1340px) {
    margin-top: 55px;
  }
  @media only screen and (max-width: 1194px) {
    height: auto;
    max-height: 77vh;
  }
`

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
  @media only screen and (max-width: 1440px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: 1370px) {
    margin-top: -250px;
  }
  @media only screen and (max-width: 1100px) {
    margin-top: -200px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -180px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: -80px;
  }

  h1 {
    font-size: 70px;
    line-height: 112px;
    font-weight: 700;
    color: ${themeGet("colors.white")};
    margin-bottom: 18px;
    @media only screen and (max-width: 990px) {
      line-height: 60px;
      font-size: 50px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 40px;
      line-height: 44px;
    }
  }

  p {
    max-width: 544px;
    color: ${themeGet("colors.white")};
    font-size: 28px;
    line-height: 40px;
    @media only screen and (max-width: 990px) {
      font-size: 24px;
      line-height: 36px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 36px;
    }
  }
`

export const ButtonGroup = styled.div`
  margin-top: 48px;
  @media only screen and (max-width: 990px) {
    margin-top: 40px;
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .primary {
    margin-right: 32px;
    text-transform: inherit;
    border-radius: 0px;
    padding: 18px 58px;
    font-size: 20px;
    background-color: ${themeGet("colors.white")};
    color: ${themeGet("colors.black")};
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
      padding: 15px 10px;
      font-size: 16px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    &.primary {
      &:hover {
        background-color: transparent;
        border: 1px solid ${themeGet("colors.white")};
        color: ${themeGet("colors.white")};
      }
    }
  }

  &.text {
    .btn-icon {
      svg {
        width: auto;
        height: 25px;
      }
    }
    &:hover {
      color: ${themeGet("colors.primaryHover")};
    }
  }

  .sec {
    text-transform: inherit;
    border-radius: 0px;
    padding: 18px 58px;
    font-size: 20px;
    background-color: transparent;
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
      padding: 15px 10px;
      font-size: 16px;
      width: 100%;
    }
    &.sec {
      &:hover {
        background-color: ${themeGet("colors.mainBlue")};
        border: 1px solid ${themeGet("colors.mainBlue")};
      }
    }
    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover")};
      }
    }
  }
`
export const Overlay = styled.div`
  width: 100%;
  min-height: 92vh;
  background-color: rgba(0, 0, 0, 0.4);
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1370px) {
      flex-wrap: wrap-reverse;
      justify-content: center;
      align-content: center;
      min-height: 92vh;
    }
  }
`

export default BannerWrapper
