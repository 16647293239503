import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const CertifiedWrapper = styled.div`
  position: relative;
  margin-top: -100px;
  background-color: ${themeGet("colors.mainBlue")};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 60px 10px;

  @media only screen and (max-width: 990px) {
    padding: 50px 50px;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 30px 30px;
    flex-direction: column;
    margin-top: -50px;
  }
  @media only screen and (max-width: 400px) {
    padding: 30px 30px 30px;
    flex-direction: column;
    margin-top: 0px;
  }
`

export const CertifiedItem = styled.div`
  text-align: center;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  h2 {
    font-family: "Open Sans Bold", sans-serif;
    font-size: 40px;
    line-height: 68px;
    font-weight: 600;
    color: ${themeGet("colors.white")};
    margin-right: 20px;
    @media only screen and (max-width: 990px) {
      font-size: 32px;
      line-height: 36px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
`

export const CertifiedLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
  .image {
    margin: 0 10px;
    width: 140px;
    @media only screen and (max-width: 480px) {
      font-size: 40px;
      width: 120px;
    }
  }
`
