import {
  CertifiedItem,
  CertifiedLogos,
  CertifiedWrapper,
} from "./certifiedPartner.style"

import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Image from "gatsby-image"
import React from "react"
import striptags from "striptags"

const CertifiedPartnerSection = ({ content }) => {
  const { items, primary } = content

  return (
    <Container width="1220px">
      <CertifiedWrapper>
        <CertifiedItem>
          <Heading
            as={primary.title.html.substring(1, 3)}
            content={striptags(primary.title.html)}
          />
        </CertifiedItem>
        <CertifiedLogos>
          {items.map((item, index) => {
            return (
              <div className="image" key={index}>
                <Image
                  fadeIn={false}
                  loading="lazy"
                  alt="Treatment Stories"
                  fluid={item.image.localFile?.childImageSharp?.fluid}
                />
              </div>
            )
          })}
        </CertifiedLogos>
      </CertifiedWrapper>
    </Container>
  )
}

export default CertifiedPartnerSection
