import SectionWrapper, { ContentWrapper } from "./about.style"

import Container from "../../components/UI/Container"
import React from "react"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"

const About = ({ content }) => {
  const { items, primary } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          marginBottom
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
        />
        <ContentWrapper>
          <div className="description">
            <Text content={primary.description_a} />
            <Text content={primary.description_b} className="description_b" />
            <div className="badges">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://clutch.co/profile/alpacked?utm_source=clutch_top_company_badge&utm_medium=image_embed#summary"
              >
                <img
                  style={{ width: 130 }}
                  src="https://shareables-static.clutch.co/badges/top_clutch.co_it_services_company_ukraine_2023.svg"
                  alt="GoodFirms Badge"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.goodfirms.co/company/alpacked"
              >
                <img
                  style={{ width: 130 }}
                  src="https://assets.goodfirms.co/badges/color-badge/devops-companies.svg"
                  alt="GoodFirms Badge"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://clutch.co/profile/alpacked?utm_source=clutch_top_company_badge&utm_medium=image_embed"
              >
                <img
                  style={{ width: 130 }}
                  src="https://shareables-static.clutch.co/badges/top_clutch.co_cybersecurity_company_ukraine_2023.svg"
                  alt="GoodFirms Badge"
                />
              </a>
            </div>
          </div>
          <div className="services">
            {items.map((item, index) => {
              return (
                <div className="service_item" key={index}>
                  <div className="service_image">
                    <img loading="lazy" src={item.icon.url} alt="service" />
                  </div>
                  <div>
                    <Text
                      content={item.solution_name.text}
                      className="service_header"
                    />
                    <Text
                      content={item.solution_description}
                      className="service_text"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default About
