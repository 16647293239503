import styled from "styled-components"

const TeamSectionWrapper = styled.section`
  padding: 100px 0 100px;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 40px 0 20px;
  }
  .feature__block {
    position: relative;
    height: 100%;
  }
`

export default TeamSectionWrapper
