import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import PropTypes from "prop-types"
import React from "react"
import TeamBlock from "../../components/TeamBlock"
import TeamSectionWrapper from "./team.style"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"

const TeamSection = ({
  row,
  col,
  TeamTitle,
  TeamDescription,
  OccupationTitle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  content,
}) => {
  const { items, primary } = content

  return (
    <TeamSectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description.html}
          subTitleMaxWidth="544px"
        />

        <Box className="row" {...row}>
          {items.map((team, index) => {
            return (
              <Box className="col" {...col} key={`team-${index}`}>
                <TeamBlock
                  icon={
                    <img
                      loading="lazy"
                      src={team?.image?.localFile?.childImageSharp?.fluid?.src}
                      alt="Team Member"
                      className="banner_image"
                    />
                  }
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={
                    <Heading
                      content={team.team_member_name.text}
                      {...TeamTitle}
                    />
                  }
                  occupation={
                    <Heading
                      content={team.team_member_occupation}
                      {...OccupationTitle}
                    />
                  }
                  description={
                    <Text
                      content={team.team_member_details}
                      {...TeamDescription}
                    />
                  }
                />
              </Box>
            )
          })}
        </Box>
      </Container>
    </TeamSectionWrapper>
  )
}

// FeatureSection style props
TeamSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  TeamTitle: PropTypes.object,
  TeamDescription: PropTypes.object,
  OccupationTitle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  blockWrapperStyle: PropTypes.object,
  content: PropTypes.object,
}

// TeamSection default style
TeamSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  // TeamSection row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: ["space-center", "space-around"],
    marginTop: "50px",
  },
  // TeamSection col default style
  col: {
    width: ["100%", "100%", "100%", "25%"],
    mb: ["0px", "16px", "40px", "0px"],
  },
  // TeamSection block wrapper default style
  blockWrapperStyle: {
    my: ["30px", "20px", "0px", "0px"],
    mx: ["0px"],
  },
  // TeamSection icon default style
  iconStyle: {
    width: ["60%", "200px"],
    m: "0 auto",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "36px",
    color: "#ffffff",
    overflow: "hidden",
    mb: ["15px", "48px"],
  },
  // TeamSection content default style
  contentStyle: {
    textAlign: "center",
  },
  // TeamSection title default style
  TeamTitle: {
    fontSize: ["20px", "22px", "22px", "24px"],
    fontWeight: "700",
    color: "#000",
    lineHeight: "30px",
    letterSpacing: "-0.020em",
    mb: ["4px", "0px"],
  },
  // TeamSection title default style
  OccupationTitle: {
    fontWeight: "300",
    fontSize: ["16px", "16px"],
    color: "#000",
    lineHeight: "18px",
    mb: ["16px", "16px", "24px", "24px"],
    mt: ["0px", "0px", "8px", "8px"],
  },
  // TeamSection description default style
  TeamDescription: {
    fontWeight: "300",
    fontSize: ["16px", "18px"],
    lineHeight: "27px",
    color: "#000",
  },
}

export default TeamSection
