import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import About from "../containers/About"
import BlogPostsList from "../containers/BlogPostsList"
import CertifiedPartner from "../containers/CertifiedPartner"
import Consultation from "../containers/Consultation"
import DevOpsServices from "../containers/DevOpsServices"
import Layout from "../containers/Layout"
import MainBanner from "../containers/MainBanner"
import More from "../containers/More"
import SEO from "../components/SEO"
import Team from "../containers/Team"
import Testimonials from "../containers/Testimonials"
import WhatWeDo from "../containers/WhatWeDo"
import { ContentWrapper } from "../containers/alpacked.style"

const IndexPage = ({ data, location }) => {
  const { edges } = data.allPrismicBlogPost
  const {
    body,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = data.prismicHome.data
  const { totalCount: totalCountServices } = data.allPrismicServicesPage
  const { totalCount: totalCountConsulting } = data.allPrismicConsulting
  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/home">
        <ContentWrapper>
          <MainBanner
            title={body[0].items[0].title.html}
            subtitle={body[0].items[0].subtitle}
            mainButtonText={body[0].items[0].button_a_title}
            mainButtonLink={body[0].items[0].button_a_link}
            secButtonText={body[0].items[0].button_b_title}
            secButtonLink={body[0].items[0].button_b_link}
            image={body[0].items[0].image.url}
          />
          <CertifiedPartner content={body[1]} />
          <About content={body[2]} />
          <WhatWeDo
            content={body[3]}
            totalCountConsulting={totalCountConsulting}
            totalCountServices={totalCountServices}
          />
          <DevOpsServices content={body[4]} />
          <Consultation colorReverse pathname="/home" />
          <Team content={body[5]} />
          <Testimonials content={body[6]} />
          <BlogPostsList content={edges} home />
          <More content={body[7]} />
          <Consultation greyBg pathname="/home" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withPreview(IndexPage)

export const HomePage = graphql`
  query HomePage {
    allPrismicBlogPost(
      sort: { fields: first_publication_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            category
            featured_article
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            author {
              slug
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    occupation {
                      text
                    }
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1400, quality: 72) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicServicesPage {
      totalCount
    }
    allPrismicConsulting {
      totalCount
    }
    prismicHome {
      data {
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        body {
          ... on PrismicHomeBodyBanner {
            id
            items {
              title {
                html
              }
              subtitle
              button_a_link
              button_a_title
              button_b_link
              button_b_title
              image {
                url
              }
            }
          }
          ... on PrismicHomeBodyDevopsServices {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            items {
              benefit_title {
                text
              }
              benefit_description
            }
          }
          ... on PrismicHomeBodyMore {
            id
            primary {
              title {
                html
              }
              title_colored1 {
                html
              }
              column_a {
                html
              }
              column_b {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyCertifiedPartners {
            id
            primary {
              title {
                html
              }
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyAbout {
            id
            primary {
              title_colored {
                html
              }
              title {
                html
              }
              description_a
              description_b
            }
            items {
              solution_name {
                text
              }
              solution_description
              icon {
                url
              }
            }
          }
          ... on PrismicHomeBodyTeam {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
              description {
                html
              }
            }
            items {
              team_member_name {
                text
              }
              team_member_occupation
              team_member_details
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyTestimonials {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
            }
            items {
              name {
                text
              }
              occupation
              testimonial
              linkedin_url {
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomeBodyWhatWeDo {
            id
            primary {
              title {
                html
              }
              title_colored {
                html
              }
              description {
                html
              }
            }
            items {
              service_name {
                text
              }
              description {
                html
              }
              icon {
                url
              }
            }
          }
        }
      }
    }
  }
`
