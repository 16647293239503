import React from "react"
import { Link } from "gatsby"

import Arrow from "../../common/assets/image/alpacked/services/more.svg"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Services from "../../components/Services"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"
import SectionWrapper, { Discover, FeatureWrapper } from "./whatWeDo.style"

const WhatWeDoSection = ({
  content,
  totalCountServices,
  totalCountConsulting,
}) => {
  const { primary, items } = content

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
          subtitle={primary.description.html}
          subTitleMaxWidth="544px"
        />
        <FeatureWrapper>
          {items.map((item, index) => {
            return (
              <Services
                key={`service_key${index}`}
                thumbUrl={item.icon.url}
                title={item.service_name.text}
                details={item.description.html}
              />
            )
          })}
          <Discover>
            <div className="number-wrapper">
              <Text content={`+`} className="icon" />
              <Text
                content={`${totalCountConsulting + totalCountServices - 1}`}
                className="number"
              />
            </div>
            <Text content="DISCOVER ALL OUR SERVICES" className="description" />
            <Link to="/services/">
              <Button
                className="button"
                icon={<img loading="lazy" src={Arrow} alt="arrow" />}
              />
            </Link>
          </Discover>
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default WhatWeDoSection
