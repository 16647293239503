import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  background-color: ${themeGet("colors.black")};
  padding: 100px 0px 0px 0px;
  @media only screen and (max-width: 1220px) {
    padding: 100px 0px 0px 0px;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 0px 0px 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0px 0px 0px;
  }
  .content {
    position: relative;
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column-reverse;
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  left: 58%;
  top: -50%;
  transform: translate(-58%, 2%);
  max-height: 645px;
  margin-top: -8px;
  .image {
    width: 1031px;
    height: 638px;
  }
  @media only screen and (max-width: 1440px) {
    left: 33%;
    top: -50%;
    transform: translate(-50%, 4%);
    .image {
      width: 600px;
      height: 638px;
    }
  }

  @media only screen and (max-width: 1100px) {
    display: block;
    left: 0;
    top: none;
    transform: none;
    margin-bottom: 30px;
    .image {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    display: block;
    left: 0;
    top: none;
    transform: none;
    margin-bottom: 30px;
    .image {
      width: 100%;
      height: 300px;
    }
  }
`

export const TextWrapper = styled.div`
  min-width: 540px;
  padding-bottom: 120px;
  @media only screen and (max-width: 1100px) {
    padding-left: 0;
    width: 100%;
    min-width: 100%;
    padding-bottom: 50px;
  }

  .details {
    margin-bottom: 48px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 24px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 40px;
    }
  }

  .title {
    color: ${themeGet("colors.white")};
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .content {
    color: ${themeGet("colors.white")};
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
      line-height: 30px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .seperator {
    border: 1px solid ${themeGet("colors.mainBlue")};
    margin: 16px 0 16px 0px;
    width: 40px;
    margin-left: 0;
  }
`

export default SectionWrapper
