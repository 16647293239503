import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  position: relative;
  background-color: ${themeGet("colors.lightGrey")};
  padding: 160px 0px 80px 0px;
  @media only screen and (max-width: 1220px) {
    padding: 100px 0px 0px 0px;
  }
  @media only screen and (max-width: 1100px) {
    height: auto;
  }
  @media only screen and (max-width: 990px) {
    padding: 80px 0px 80px 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0px 50px 0px;
  }
  .content {
    display: flex;
    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
`

export const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    position: absolute !important;
    @media only screen and (max-width: 1100px) {
      position: relative !important;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      position: relative !important;
      margin-bottom: 30px;
    }
  }
  .image {
    width: 1028px;
    height: 645px;
    @media only screen and (max-width: 1100px) {
      width: auto;
      height: 545px;
    }
    @media only screen and (max-width: 990px) {
      width: auto;
      height: 445px;
    }
    @media only screen and (max-width: 768px) {
      width: auto;
      height: 400px;
    }
    @media only screen and (max-width: 480px) {
      width: auto;
      height: 245px;
    }
  }
`

export const TextWrapper = styled.div`
  width: 37%;
  margin-right: 120px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  .free-content {
    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
    p {
      margin-top: 0px !important;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 28px;
      line-height: 30px;
      @media only screen and (max-width: 990px) {
        font-size: 18px;
        margin-bottom: 24px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
      }
    }
    ul {
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 24px;
      @media only screen and (max-width: 990px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;
      }
      li {
        font-weight: 300;
        margin-bottom: 4px;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 0px 0;
        }
        @media only screen and (max-width: 990px) {
          padding-left: 23px;
          text-indent: -23px;
        }
      }
    }
  }
`
export const TextWrapperB = styled.div`
  width: 55%;
  position: relative;
  margin-top: 700px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin-top: 0px;
  }

  .free-content {
    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
    p {
      margin-top: 0px !important;
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 32px;
      line-height: 30px;
      @media only screen and (max-width: 990px) {
        font-size: 20px;
        margin-bottom: 24px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
      }
    }

    h3 {
      margin-bottom: 16px;
    }

    ul {
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 24px;
      @media only screen and (max-width: 990px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;
      }
      li {
        font-weight: 300;
        margin-bottom: 0px;
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 26px;
        text-indent: -26px;
        @media only screen and (max-width: 990px) {
          margin: 0px 0;
        }
        @media only screen and (max-width: 990px) {
          padding-left: 23px;
          text-indent: -23px;
        }
      }
    }
  }
`

export default SectionWrapper
