import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.div`
  padding: 100px 0 0px;
  overflow: hidden;
  @media only screen and (max-width: 990px) {
    padding: 80px 0 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 0px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .description {
    display: flex;
    flex-direction: column;
    width: 55.5%;
    margin-right: 8.5%;
    @media only screen and (max-width: 990px) {
      width: 100%;
      margin-right: 0;
    }

    .badges {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      gap: 16px;
      @media only screen and (max-width: 990px) {
        margin-bottom: 52px;
      }
      @media only screen and (max-width: 568px) {
        margin-bottom: 24px;
      }
    }

    .description_b {
      margin-top: 66px;
      background-color: ${themeGet("colors.lightGrey")};
      padding: 50px 80px 50px 374px;
      margin-left: -374px;
      @media only screen and (max-width: 990px) {
        padding: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: transparent;
      }
    }

    p {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: ${themeGet("colors.black")};
      margin-bottom: 36px;
      @media only screen and (max-width: 990px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }
  }

  .services {
    width: 36%;
    @media only screen and (max-width: 990px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .service_item {
      display: flex;
      padding: 32px 20px;
      -webkit-box-shadow: 0px 0px 9px 4px rgba(219, 219, 219, 0.3);
      -moz-box-shadow: 0px 0px 9px 4px rgba(219, 219, 219, 0.3);
      box-shadow: 0px 0px 9px 4px rgba(219, 219, 219, 0.3);
      margin-bottom: 30px;
      @media only screen and (max-width: 990px) {
        padding: 20px;
      }
      @media only screen and (max-width: 768px) {
        padding: 20px;
      }

      .service_image {
        margin-right: 30px;
        align-items: center;
        margin-bottom: 16px;
        img {
          width: 82px;
          @media only screen and (max-width: 768px) {
            width: 80px;
          }
        }
        @media only screen and (max-width: 480px) {
          margin-right: 24px;
        }
      }
      .service_header {
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 700;
        @media only screen and (max-width: 1200px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 20px;
        }
      }
      .service_text {
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0px;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
`

export default SectionWrapper
