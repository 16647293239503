import SectionWrapper, {
  ImageWrapper,
  TextWrapper,
  TextWrapperB,
} from "./more.style"

import Container from "../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import TitleSection from "../TitleSection"

const More = ({ content }) => {
  const { primary } = content

  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored1.html}
          subTitleMaxWidth="520px"
        />
      </Container>
      <Container width="1220px" className="content">
        <TextWrapper>
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_a.html }}
            className="free-content"
          />
        </TextWrapper>
        <ImageWrapper>
          <Img
            fadeIn={false}
            loading="lazy"
            className="image"
            fluid={primary.image.localFile?.childImageSharp.fluid}
            alt="mission-img"
          />
        </ImageWrapper>
        <TextWrapperB>
          <div
            dangerouslySetInnerHTML={{ __html: primary.column_b.html }}
            className="free-content"
          />
        </TextWrapperB>
      </Container>
    </SectionWrapper>
  )
}

export default More
