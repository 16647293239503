import React from "react"
import striptags from "striptags"
import { Link } from "gatsby"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import BannerWrapper, {
  BannerContent,
  ButtonGroup,
  Overlay,
} from "./mainBanner.style"

const MainBanner = ({
  title,
  subtitle,
  mainButtonText,
  mainButtonLink,
  secButtonLink,
  secButtonText,
  image,
}) => {
  return (
    <BannerWrapper image={image}>
      <Overlay>
        <Container width="1220px">
          <BannerContent>
            <Heading as={title.substring(1, 3)} content={striptags(title)} />
            <Text content={subtitle} />
            <ButtonGroup>
              <Link to={`${mainButtonLink}/`}>
                <Button className="primary" title={mainButtonText} />
              </Link>
              <Link to={`${secButtonLink}/`}>
                <Button className="sec" title={secButtonText} />
              </Link>
            </ButtonGroup>
          </BannerContent>
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default MainBanner
