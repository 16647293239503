import SectionWrapper, {
  ImageWrapper,
  TextWrapper,
} from "./devOpsServices.style"

import Container from "../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import Text from "../../components/Text"
import TitleSection from "../TitleSection"

const DevOpsServices = ({ content }) => {
  const { items, primary } = content
  return (
    <SectionWrapper>
      <Container width="1220px">
        <TitleSection
          reverseColor
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
        />
      </Container>
      <Container width="1220px" className="content">
        <TextWrapper>
          {items.map((item, index) => {
            return (
              <div key={index} className="details">
                <Text content={item.benefit_title.text} className="title" />
                <hr className="seperator" />
                <Text content={item.benefit_description} className="content" />
              </div>
            )
          })}
        </TextWrapper>
        <ImageWrapper>
          <Img
            fadeIn={false}
            loading="lazy"
            className="image"
            fluid={primary.image.localFile?.childImageSharp.fluid}
            alt="mission-img"
          />
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default DevOpsServices
